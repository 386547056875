.ubf-mainMenu {
	@include storeValuesForJs((enableScrollMonitor: false));
	@include ease(opacity visibility, $duration4);
	background-color: $colorBgSubMenu;
	display: flex;
	flex-direction: column;
	opacity: 0;
	overflow: hidden;
	padding-bottom: $base2;
	padding-top: ($headerHeightSmall + $base2);
	transform: translateX(-100%);

	@include media($xlargeLayoutBreakpoint) {
		@include storeValuesForJs((enableScrollMonitor: true));
		background-color: $colorBgBase;
		display: block;
		opacity: 1;
		overflow: visible;
		padding: 0;
		transform: none;
		transition: none;
	}

	html:not(.no-js) & {
		visibility: hidden;

		@include media($xlargeLayoutBreakpoint) {
			visibility: visible;
		}
	}

	.ubf-body--ekvv & {
		background-color: $colorBgBase;
	}

	// not supported on Edge and IE but not important, because this is needed only for small devices
	&:focus-within {
		outline: none;

		html.no-js & {
			opacity: 1;
			transform: none;
			height: auto;
		}
	}



	&.ubf-js-beforeActive {
		transform: none;
		will-change: opacity, visibility;
	}

	html:not(.no-js) &.ubf-js-duringActive {
		@include fadeIn();
	}

	&.ubf-js-covered[data-ubf-covered-by="modal"],
	&.ubf-js-covered[data-ubf-covered-by="alert"] {
		@include media($xlargeLayoutBreakpoint) {
			position: fixed;
			width: 100%;
			left: 0;
		}
	}


	&::after {
		background-color: $colorBgBase;
		content: '';
		height: $headerHeightSmall;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 30;

		@include media($xlargeLayoutBreakpoint) {
			height: $base2;
			position: absolute;
			top: 100%;
			z-index: 6;
		}

		.ubf-body--ekvv & {
			@include media($xlargeLayoutBreakpoint) {
				display: none;
			}
		}
	}


	&__content {
		pointer-events: all;
		@include ubf-container();
		order: 2;

		@include media($ibridLayoutBreakpoint) {
			padding-left: $logoMarginSmall;
			padding-right: $base + $logoSizeSmall - $menuTogglerWidth;
		}

		@include media($xlargeLayoutBreakpoint) {
			@include ubf-container();
			@include staticColumns();
			height: 100%;
		}
	}


	&__item {
		@include ease(height, $duration4);
		overflow: hidden;
		position: relative;
		z-index: 7;
		&:hover {
		  background-color: $colorBgSubMenu;
		}

		&--loginDesktop {
			margin: $base3 0;
			display:none;
			@include media($xlargeLayoutBreakpoint) {
				display:block;
			}
		}
		&--loginMobile {
			display:block;
			@include media($xlargeLayoutBreakpoint) {
				display:none;
			}
		}
		&--myUni {
			margin: $base3 0;
		}
		& + & {
			margin-top: $base2;

			@include media($xlargeLayoutBreakpoint) {
				margin-top: 0;
				margin-left: 0; //$baseHalf;
				background-color: $colorBgBase;
				box-sizing: border-box;
				border-left: 16px solid #fff;
				&:hover {
				  background-color: $colorBgSubMenu;
				}
			}

			.ubf-body--ekvv & {
				@include media($xlargeLayoutBreakpoint) {
					margin-left: $base3;
				}

				@include media('>=large') {
					margin-left: $base4;
				}

				@include media('>=xLarge') {
					margin-left: $base6;
				}
			}
		}

		@include media($xlargeLayoutBreakpoint) {
			overflow: visible;
			position: relative;
			transition: none;
			width: staticColumnSize(1.8, 9, 0);
		}

		.ubf-mainMenu--extended & {
			@include media($xlargeLayoutBreakpoint) {
				width: auto;
			}
		}

		.ubf-body--library .ubf-mainMenu--extended & {
			@include media($xlargeLayoutBreakpoint) {
				width: auto!important;			}
		}



		.ubf-body--ekvv & {
			@include media($xlargeLayoutBreakpoint) {
				width: auto;
				flex-grow: 0;
				flex-shrink: 0;
			}
		}

		&--lang {
			text-transform: uppercase;
			@include media($xlargeLayoutBreakpoint) {
				float: none;
				line-height: inherit;
				width: 50px;
				background-color: white !important;
				border-left: none!important;
				flex-basis: 30px;
				flex-shrink: 0;
				flex-grow: 0;
			}

			.ubf-mainMenu--extended & {
				@include media($xlargeLayoutBreakpoint) {
					width: 50px;
				}
			}
		}
	}

	&__items {
		@include media($xlargeLayoutBreakpoint) {
			@include staticColumns();
			align-items: stretch;
			height: 100%;
			width: calc(100% - 50px);
		}
	  	& > * {
		  flex-grow: 1;
		  padding-left: 1rem;
			&.ubf-mainMenu__item--lang {
				flex-grow: 0;
			}
		}

		.ubf-body--ekvv & {
			@include media($xlargeLayoutBreakpoint) {
				justify-content: flex-start;
				width: 100%;
			}
		}


		.ubf-body--ekvv &::before {
			@include media($xlargeLayoutBreakpoint) {
				content: ' ';
				display: block;
				flex-grow: 0;
				flex-shrink: 0;
				max-width: ubf-ekvvMaxSize(3, 12);
				width: staticColumnSize(3, 12, $gridGutterLarge);
			}
		}
	}


	&__link {
		@include storeValuesForJs((togglerEnabled: true));
		font-weight: bold;
		display: flex;
		flex-direction: row;
		align-items: center;
		color: $colorBase;
		position: relative;
		text-decoration: none;
		z-index: 2;

		&:hover {
			text-decoration: none;
			color: $colorBase;
		}

		&:focus {
			outline: none;
		}

		@include media($xlargeLayoutBreakpoint) {
			@include storeValuesForJs((togglerEnabled: false));
			//height: 100%;
			height: $menuHeight;
		}
	}


	&__linkIcon {
		@include ease(transform, $duration4);
		line-height: 0;
		height: $base;
		margin-right: $base * 0.5;
		position: relative;
		text-align: center;
		width: $base;


		html.no-js .ubf-mainMenu__item:focus-within &,
		.ubf-mainMenu__link.ubf-js-toggled & {
			transform: rotate(90deg);

			@include media($xlargeLayoutBreakpoint) {
				transform: none;
			}
		}

		.ubf-mainMenu__item:hover & {
			@include media($xlargeLayoutBreakpoint) {
				@include ease(transform, $duration2);
				transform: rotate(90deg);
			}

			.ubf-mainMenu.ubf-js-alternative & {
				@include media($xlargeLayoutBreakpoint) {
					transform: rotate(-90deg);
				}
			}
		}


		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}


	&__linkLabel {
		@include ubf-outlineOnFocus('.ubf-mainMenu__link', '&');

		@include media($xlargeLayoutBreakpoint) {
			align-self: flex-start;
			margin-top: $menuHeight * 0.5;
			transform: translateY(-#{getLineHeight(base) * getFontSize(base) * 0.5});
		}

		&--currentLang {
			font-weight: bold;
			@include ubf-highlightColor(color);
		}
	}


	&__mainMenuToggler {
		@include ubf-container();
		left: 50%;
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		display: none;
		z-index: 31;

		html.no-js & {
			display: block;

			@include media($xlargeLayoutBreakpoint) {
				display: none;
			}
		}
	}


	&__mainMenuTogglerLink {
		height: $menuTogglerHeight;
		margin-top: -($menuTogglerHeight * 0.5);
		position: absolute;
		right: $base;
		top: $headerHeightSmall * 0.5;
		width: $menuTogglerWidth;

		@include media($ibridLayoutBreakpoint) {
			right: $base + $logoSizeSmall - $menuTogglerWidth;
		}
	}


	&__searchBox {
		margin-top: $base2;
		position: relative;

		@include media($xlargeLayoutBreakpoint) {
			display: flex;
			flex-direction: column;
			height: $menuHeight;
			margin-left: auto;
			justify-content: center;
			margin-top: 0;
			width: 50px;
			z-index: 100;
			background-color: white;
			cursor:pointer;

			.ubf-mainMenu__icon {
				padding-top: 5px;
				padding-right: 10px;
				cursor: pointer;
			}
		}

		&.active::after {
			@include media($xlargeLayoutBreakpoint) {
				content: '';
				position: absolute;
				background-color: transparent;
				top: 0;
				right: 0;
				width: $gridMaxWidth !important;
				height: 100%;
				z-index: -1;
			}
		}
	}


	&__searchInput {
		background-color: $colorBgBase;
		border: 0;
		color: $colorBase;
		line-height: $searchInputLineHeightSmall;
		margin-left: auto;
		max-width: calc(100% - #{$base6}) !important;
		padding: 0 $base;
		width: calc(100% - #{$base6}) !important;

		+ .ubf-mainMenu__icon {
			display: none;
		}

		@include media($xlargeLayoutBreakpoint) {
			background-color: $colorBgSearchInput;
			line-height: inherit;
			padding: 0 $base2;
			width: 0 !important;
			max-width: none !important;
			transition: all 0.15s ease;//, opacity 0.3s ease;
			opacity: 0;
			margin-top: 6px !important;
			height: 45px;
			margin-bottom: 0 !important;
			float: right;
			right: 0;
			position: absolute;
			cursor: pointer;

			+ .ubf-mainMenu__icon {
				display: inline;
			}

			.ubf-mainMenu__searchBox:focus &,
			.ubf-mainMenu__searchBox.active &,
			&:focus {
				opacity: 1;
				display: block;
				width: $gridMaxWidth !important;
				max-width: none !important;
				z-index: 100;
			}

			&::placeholder {
				color: $colorBase;
			}
		}
	}

	&__searchSubmit {
		@include ubf-outlineOnFocus();
		height: calc(#{$base} * 3.5 - 2px);
		position: absolute;
		right: $base6;
		top: 50%;
		transform: translateY(-50%);

		@include media($xlargeLayoutBreakpoint) {
			height: calc(#{$base} * 2.5 - 2px);
			display: none;
			right: 13px;
			top: 42px;
		}

		.ubf-mainMenu__searchBox:focus &,
		.ubf-mainMenu__searchBox.active & {
			@include media($xlargeLayoutBreakpoint) {
				display: block;
				z-index: 200;
			}
		}
	}

	&__searchIcon {
		[data-type="svg"] {
			border: 1px solid $colorBase;
			height: 100%;
		}
	}

	&__icon {
		[data-type="svg"] {
			height: 80%;
			float: right;
		}

		.ubf-mainMenu__searchBox:focus &,
		.ubf-mainMenu__searchBox.active & {
			display: none;
		}
	}


	&__readerText {
		@include visuallyHidden();
	}


	&__secondaryMenu {
		@include ubf-container();
		margin-bottom: $base4;

		@include media($ibridLayoutBreakpoint) {
			padding-left: $logoMarginSmall;
			padding-right: $base + $logoSizeSmall - $menuTogglerWidth;
		}

		@include media($xlargeLayoutBreakpoint) {
			display: none;
		}
	}


	&__subItem {
		& + & {
			 margin-top: $base * 1.5;
		}
	  @include media($xlargeLayoutBreakpoint) {
		& + & {
		  margin-top: 0;
		}
		padding-top: $base * 1.5;
		padding-right: $base2;
		width:250px;
	  }
	}


	&__subLink {
		&--login {
			display: block;
			text-decoration: underline;
		}
		@include ubf-outlineOnFocus();
		color: $colorSubNav;
		text-decoration: none;

		@include onHover {
			color: $colorBase;
		}


	}


	&__subMenu {
		@mixin showSubmenu() {
			opacity: 1;
			position: relative;
			transform: none;
		}

		@include ease(opacity visibility, $duration4);
		opacity: 0;
		padding-left: $base5;
		padding-top: $base2;
		position: absolute;
	  	background-color: $colorBgSubMenu;

		html.no-js & {
			transform: translateX(-200%);

			@include media($xlargeLayoutBreakpoint) {
				transform: none;
			}
		}

		html:not(.no-js) & {
			visibility: hidden;
		}

		&:focus,
		&:focus-within {
			outline: none;

			html.no-js & {
				@include showSubmenu();
				@include media($xlargeLayoutBreakpoint) {
					position: absolute;
				}
			}
		}

		&:not(.ubf-js-collapsed):not(.legacy-js-collapsed):not(.hio-js-collapsed) {
			@include showSubmenu();

			@include media($xlargeLayoutBreakpoint) {
				position: absolute;
			}

			html:not(.no-js) & {
				visibility: inherit;
			}
		}

		@include media($xlargeLayoutBreakpoint) {
			$parentLinkHeight: (($menuHeight + getFontSize(base) * getLineHeight(base)) * 0.5 + $base3);

			@include ease(opacity visibility, $duration2, $duration2);
			@include fadeOut();

			background-color: $colorBgSubMenu;
			left: -$base2;
			padding: $base2;
			margin-top: 8rem;
		  	margin-left: 2rem;
			margin-bottom: 8rem;
			top: 0;
			transform: none;
			//width: 200%;
			z-index: 1;

			max-height: 100vh;
			display: block;
			//flex-direction: column;
			//flex-wrap: wrap;
			& > * {
			  background-color: $colorBgSubMenu;
			}

			.ubf-mainMenu.ubf-js-alternative & {
				bottom: 0;
				padding-top: $base2;
				top: auto;
			}

			.ubf-mainMenu--extended & {
				@include media($xlargeLayoutBreakpoint) {
					//width: $submenuSizeAlternate;
				}
			}
		}

		.ubf-mainMenu__item:hover > &,
		.ubf-mainMenu__link:focus + &,
		.ubf-mainMenu__item.ubf-js-focusWithin & {
			@include media($xlargeLayoutBreakpoint) {
				@include fadeIn();
			}
		}

		//this one is separately because otherwise IE problems
		.ubf-mainMenu__item:focus-within > & {
			@include media($xlargeLayoutBreakpoint) {
				@include fadeIn();
			}
		}
	}
}
